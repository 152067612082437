<template>
  <admin-fillall-layout>
    <default-layer
      ref="layers"
      :center="mapCenter"
      :zoom="mapZoom"
      :map-type-id="0"
      :overwrite-source="[olSourceEditData]"
    />
    <back-button @back-click="back" />
    <save-button
      :cancel-text="$t('button.cancel')"
      :text="$t('button.save')"
      @save="save"
      @cancel="cancel"
    />
  </admin-fillall-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DefaultLayer from '@/components/OpenLayers/DefaultLayers.vue';
import BackButton from '@/components/Maps/BackButton.vue';
import SaveButton from '@/components/Maps/SaveButton.vue';

export default {
  name: 'OlSourcePreviewApp',
  components: {
    DefaultLayer,
    BackButton,
    SaveButton,
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      mapType: 'map/type',
      mapCenter: 'map/centerOl',
      mapZoom: 'map/zoom',
      mapBoundsInteger: 'map/boundsInteger',
      olSourceList: 'user/olSource',
      olSourceEditData: 'olSource/getData',
    }),
  },
  data() {
    return {
      controls: {
        zoom: false,
      },
    };
  },
  methods: {
    back() {
      const { returnQuery } = this.$route.query;
      this.$router.push({
        name: this.$route.query.return,
        query: (returnQuery != null) ? JSON.parse(returnQuery) : null,
      });
    },
    cancel() {
      this.setEditMode(null);
      this.back();
    },
    save() {
      const data = { ...this.olSourceEditData };
      const { index } = data;
      delete data.index;
      this.setEditMode(null);

      const newSources = this.olSourceList.slice();
      if (index == null) {
        newSources.push(data);
      } else {
        newSources[index] = data;
      }
      this.setOlTileSource(newSources);
      this.back();
    },
    ...mapActions({
      setEditMode: 'olSource/setEditMode',
      setOlTileSource: 'user/setOlTileSource',
    }),
  },
};
</script>
